(function ($, site, generic) {
  /**
   * Event listener to display the waitlist form.
   * Event trigger comes from module product_waitlist_v1.
   */

  Drupal.behaviors.productWaitlistFormV1 = {
    /**
     * Drupal behavior method to handle the waitlist form display and functionality.
     * After the 'product.waitlist.form' event is fired,
     * the product's data is passed to the launch method.
     * The product_waitlist_form inline template is displayed below
     * product detail info and hide the cta button.
     * Once rendered, the waitlist form submit rpc is initialized.
     * Form submission either results in a success message or errors displaying inline.
     * All messaging copy is handled within the waitlist module in the CMS.
     */
    attach: function (context) {
      var that = this;
      var isMobile = $(window).width() < 640;
      if ($('.js-waitlist-container').parent().hasClass('js-product-quickshop__form')) {
        var $overlayCont = $('.js-waitlist-container', '.js-product-quickshop__form');
      } else if (isMobile) {
        var $overlayCont = $('.js-waitlist-container', '.js-waitlist-mobile');
      } else {
        var $overlayCont = $('.js-waitlist-container', '.js-waitlist-pc');
      }
      if (!$overlayCont) {
        return null;
      }
      var $formCont = $('.js-waitlist-form-container', $overlayCont);
      var $waitlistForm = $('.js-waitlist-form', $overlayCont);
      var $emailInput = $('.js-waitlist-email', $waitlistForm);
      var $submitButton = $('.js-waitlist-submit', $overlayCont);
      var $successCont = $('.js-waitlist-success', $overlayCont);
      var $errorList = $('.js-error-messages', $overlayCont);

      $emailInput.focus();
      $submitButton.on('click', function () {
        $waitlistForm.submit();
      });

      $waitlistForm.on('submit', function (e) {
        e.preventDefault();

        var pObj = {};
        var params = $(this).serializeArray();

        for (var i = 0, len = params.length; i < len; i++) {
          pObj[params[i].name] = params[i].value;
        }

        generic.jsonrpc.fetch({
          method: 'form.get',
          params: [pObj],
          onSuccess: function () {
            $formCont.addClass('hidden');
            $successCont.removeClass('hidden');
            $(document).trigger('notifySuccess');
            return;
          },
          onFailure: function (r) {
            var errorObj = r.getError();
            var errorArray = errorObj.data.messages;
            $(document).trigger('notifyFail');

            /**
             * Adding this error class from this fetch callback logic because
             * it seems to not be used in any other places,
             * So I won't add it in the error.js file
             * until any decision is made (affecting all inputs)
             */
            $emailInput.addClass('error');

            if (Drupal.settings.common && Drupal.settings.common.inline_waitlist) {
              // Because the generic.showErrors function will select only nodes[0]
              // And instead changing that base function, better handle this specific case assuming it's a pretty isolated one
              for (i = 0; i <= $errorList.length; i++) {
                generic.showErrors(errorArray, $($errorList[i]));
              }
            } else {
              generic.showErrors(errorArray, $errorList);
            }
            return;
          }
        });
      });
    },
    launch: function (data) {
      var that = this;
      var isMobile = $(window).width() < 640;
      let $overlayCont = '';
      if ($('.js-waitlist-container').parent().hasClass('js-product-quickshop__form')) {
        $overlayCont = $('.js-waitlist-container', '.js-product-quickshop__form');
      } else if (isMobile) {
        $overlayCont = $('.js-waitlist-container', '.js-waitlist-mobile');
      } else {
        $overlayCont = $('.js-waitlist-container', '.js-waitlist-pc');
      }
      if (!$overlayCont) {
        return null;
      }
      var $requestType = $('.js-waitlist-request-type', $overlayCont);
      var $skuBaseId = $('.js-waitlist-sku-base-id', $overlayCont);
      var $catBaseId = $('.js-waitlist-cat-base-id', $overlayCont);
      var $successCont = $('.js-waitlist-success', $overlayCont);
      var $headerCont = $('.js-waitlist-header', $overlayCont);
      var waitlistTempOutOfStockMsg = site.translations.product.waitlist_temp_out_of_stock_msg;
      var waitlistComingSoonStockMsg = site.translations.product.waitlist_coming_soon_stock_msg;
      var waitlistSoldOutStockMsg = site.translations.product.waitlist_sold_out_stock_msg;

      // Update the Waitlist Overlay Header based on the INVENTORY_STATUS state
      var headerText =
        data.INVENTORY_STATUS === that.statusIds.inventory.tempOutOfStock
          ? waitlistTempOutOfStockMsg
          : data.INVENTORY_STATUS === that.statusIds.inventory.comingSoon
          ? waitlistComingSoonStockMsg
          : data.INVENTORY_STATUS === that.statusIds.inventory.soldOut
          ? waitlistSoldOutStockMsg
          : '';
      $headerCont.text(headerText);
      $successCont.addClass('hidden');

      // Set request type. CS - Coming Soon BIS - Back In Stock
      var requestType =
        data.INVENTORY_STATUS === that.statusIds.inventory.comingSoon ? 'CS' : 'BIS';

      $requestType.val(requestType);
      // Set sku_base_id and cat_base_id in the form.
      $skuBaseId.val(data.SKU_BASE_ID);
      let catBaseId = '';

      if (data && data.PARENT_CAT_ID) {
        var matchResult = data.PARENT_CAT_ID.match('[0-9]+');

        if (matchResult) {
          catBaseId = matchResult[0];
        }
      }
      $catBaseId.val(catBaseId);
    },

    /**
     * Status ids used by the waitlist overlay; adjust accordingly.
     */
    statusIds: {
      inventory: {
        tempOutOfStock: 2,
        comingSoon: 3,
        soldOut: 7
      },
      lifeOfProduct: {
        basicReorder: 2
      }
    }
  };
})(jQuery, window.site || {}, window.generic || {});
